#root {
  height: 100vh;
}

#outer-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.page-content {
  flex: 1;
  overflow-y: auto;
}

.footer {
  height: 56px;
}

@media (max-width: 240px) {
  .footer-bar {
    overflow-x: auto;
    justify-content: flex-start !important; /*Forced by BottomNavigation otherwise.*/
  }
}
